import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {clearProducts, fetchProducts, PRODUCTS_PAGE_COUNT} from '../Products/actions';
import { getProductsFetching, getProducts, getProductsHasMore } from '../Products/reducer';
import ProductsList from '../../components/ProductsList';
import { useParams } from 'react-router';


import { getCategories } from "../Categories/reducer";
import InfiniteView from "../../components/InfiniteView";
import { setWindowScrollPosition } from "../../components/WindowDimensions";
import config from "../../config/config";
import ProductsContainer from "../../components/ProductsContainer";
import {CATEGORIES_PAGE_COUNT, fetchCategories} from "../Categories/actions";
import ProductLoader from "../../components/ProductLoader";

export default function Tags(props) {

    const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

    const {categId} = useParams();

    const loading = useSelector((state) => getProductsFetching(state.products));
    const products = useSelector((state) => getProducts(state.products));
    const categories = [
        ...new Map(products.flatMap(product => product.categories).map(category => [category.id, category])).values()
    ];
    const tags = [
        ...new Map(products.flatMap(product => product.tags).map(tag => [tag.id, tag])).values()
    ];
    const hasMore = useSelector((state) => getProductsHasMore(state.products));
    const currentCategory = categories.find((category) => Number(category.id) === Number(categId));
    const currentCategoryTitle = currentCategory ? currentCategory.name : '';

    const currentTag = tags.find((tag) => Number(tag.id) === Number(categId));
    const currentTagTitle = currentTag ? currentTag.name : '';


    useEffect(() => {
        clearProducts()

        loadProducts()

        readCategories(categId, 1);
        setWindowScrollPosition();

        if (mixpanel) {
            mixpanel.track('Products List View', {...userLocation, ...userData, categId});
        }
    }, [categId, dispatch, mixpanel]);


    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

    function loadProducts() {
        const nextPage = Math.round(products.length / PRODUCTS_PAGE_COUNT) + 1;
        readProducts(nextPage);
    }

    const readCategories = (parent, page) => {
        dispatch(fetchCategories(parent, {
            page,
            parent,
            per_page: 50,
            orderby: 'name',
            order: 'asc',
        }));
    };

    function readProducts(page) {
        const isTag = categId >= 130;
        const isCategory = !isTag;

        dispatch(fetchProducts({
            page,
            order: 'asc',
            orderby: 'title',
            per_page: 16,
            home_category: config.CATALOG_CATEGORIES_ID,
            category: isCategory ? categId : '',
        }));
    }



    return (
        <>
            <span className='card-product title'>{currentCategory ? currentCategoryTitle : currentTagTitle}</span>
            <br/>
            <ProductLoader
                category={categId}
                loaderText="Загружаем продукты ..."
                zeroText="Продукты отсутствуют"
            />
        </>

    );
}

