import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {clearProducts, fetchProducts} from "../../views/Products/actions";
import {getProductsCompanyHasMore, getProductsFetching, getProductsHasMore} from "../../views/Products/reducer";
import ProductCard from "../ProductCard/ProductCard";
import { isMobile } from "react-device-detect";
import ProductPager from "../ProductPager";

const ProductLoader = (props) => {
    const { category, loaderText, zeroText } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        readProducts(1)
    }, []);

    const products = useSelector(state => state.products.items);
    const hasMore = useSelector(state => getProductsCompanyHasMore(state.products));

    const [isFetching, setIsFetching] = useState(false);

    const readProducts = (page) => {
        setIsFetching(true);
        dispatch(fetchProducts({
            page,
            per_page: 16,
            category: category,
            order: 'asc',
            orderby: 'name',
        }))
            .then((res) => {}/*console.log(res, 'Продукты')*/)
            .finally(() => setIsFetching(false));
    }

    const loadMore = () => {
        readProducts(Math.round(products.length / (isMobile ? 12 : 16)) + 1);
    }

    return (
        <>
            {products.length > 0 ? (
                <ProductPager
                    itemsPerPage={8}
                    dataLength={products.length}
                    dataList={products.map(product => (
                        <ProductCard
                            key={product.id}
                            id={product.id}
                            src={product.images[0]?.src}
                            name={product.name}
                            categories={product.categories}
                            tags={product.tags}
                            shortDescription={product.short_description}
                            categoryId={product.categories[0]?.id}
                            has_options={product.has_options}
                            virtual={product.virtual}
                            seller_company={product.seller_company.name}
                            seller_company_id={product.seller_company.id}
                            seller_company_logo={product.seller_company.logo}
                        />
                    ))}
                    nextFetch={loadMore}
                    columns={4}
                    rows={isMobile ? 3 : 2}
                    width={isMobile ? 8 : 4}
                    hasMore={hasMore}
                    isLoading={isFetching}
                    windowScroll={true}
                />
            ) : (
                <p style={{display:"flex", justifyContent:"center"}}>Пока нет товаров в данном сегменте</p>
            )}
        </>
    );
};

export default ProductLoader;
