import React, {useEffect, useState} from 'react';
import './styles.css';
import {useParams} from "react-router";
import {Flag, Dimmer, Loader} from 'semantic-ui-react';

import ModalImageGallery from "../../components/ModalImageGallery";
import ProductPager from "../../components/ProductPager";
import ProductCard from "../../components/ProductCard/ProductCard";
import {photos} from "./constants";
import {getCompanies} from "../CongressOnline/reducer";
import {fetchCompanies} from "../CongressOnline/actions";
import {fetchProducts} from "../Products/actions";
import {getProducts, getProductsFetching, getProductsCompanyHasMore} from "../Products/reducer";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import {toNumber} from "lodash/lang";


function Company(props) {
    const PAGE_COUNT = isMobile ? 12 : 16;

    const { dispatch, navigate } = props;
    const {companyId} = useParams();
    const [screenWidth, setScreenWidth] = useState(window.screen.width);
    const [modalActive, setModalActive] = useState(false);
    const [modalIndex, setModalIndex] = useState(1);
    const [isLoadingCompany, setIsLoadingCompany] = useState(false);

    const [location, setLocation] = useState('about');
    const company = useSelector((state) => getCompanies(state.companies)[0]);
    const products = useSelector((state) => getProducts(state.products));
    const hasMore = useSelector((state) => getProductsCompanyHasMore(state.products));
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        handleResize();
        setIsLoadingCompany(true);
        readCompany();
        readProducts(1)
        window.scrollTo(0, 0);
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setScreenWidth(window.screen.width);
    };


    // console.log(company)
    const readProducts = (page) => {
        setIsFetching(true);
        dispatch(fetchProducts({
            page,
            per_page: isMobile ? 12 : 16,
            seller_id: companyId,
            order: 'asc',
            orderby: 'name',
        }))
            .then((res) => {}/*console.log(res, 'Продукты')*/)
            .finally(() => setIsFetching(false));
    }
    const readCompany = () => {
        dispatch(fetchCompanies({id: companyId}))
            .then(() => setIsLoadingCompany(false))
            .catch(() => setIsLoadingCompany(false));
    }

    const loadMore = () => {
        // console.log('loadMore', products.length, hasMore);
        readProducts(Math.round(products.length / (isMobile ? 12 : 16)) + 1);
    }

    const showModal = (index) => {
        setModalIndex(index);
        setModalActive(true);
    }

    const arrayPhoto = photos.map((photo) => (
        <div className='company-card-container'>
            <img
                 className='company-card-info'
                 id={photo.id}
                 src={photo.original}
                 onClick={() => showModal(photo.id)}
                 alt='Фотография'
            />

        </div>
    ));

    const arrayProducts = products.map((product) => (
        <ProductCard
            key={product.id}
            id={product.id}
            src={product.images[0].src}
            name={product.name}
            categories={product.categories}
            tags={product.tags}
            shortDescription={product.short_description}
            categoryId={product.categories[0].id}
            has_options={product.has_options}
            seller_company={product?.seller_company?.name || ''}
            seller_company_id={product?.seller_company?.id || ''}
            seller_company_logo={product?.seller_company?.logo || ''}
            virtual={product.virtual}
        />
    ));

    return (
        <div className='company-view'>
            <h1 className='company-title'>{company.name}</h1>
            <div className='company-wrapper' >
                <div className='company-info'>
                    <img className='company-logo' src={company.logo} alt='Логотип'/>
                    <div className='company-description'>
                        <div className='company-text-container'>
                            <Flag className='company-flag' name={company.billing.country.toLowerCase()}
                                  alt='Флаг'/>
                            <p className='company-text'>{company.billing.country} {company.billing.city && ', ' + company.billing.city}</p>
                        </div>
                        {/*<p className='company-text company-text-2'>{company.segments.map((elem, index) => index === company.segments.length - 1 ? elem.name : elem.name + ', ')}</p>*/}
                        <div className='company-categories'>
                            <p className='company-text company-text-3'>Категории:</p>
                            {screenWidth > 768 &&
                                <div className='company-categories-container'>
                                    {company.segments.map((elem) =>
                                        <p className='company-categories-text'>{elem.name}</p>)
                                    }
                                </div>
                            }


                        </div>
                    </div>
                </div>
                {screenWidth <= 768 &&
                    <div className='company-categories-container'>
                        {company.segments.map((elem) =>
                            <p  onClick={() => navigate(`/products/${elem.id}`)} className='company-categories-text'>{elem.name}</p>)
                        }
                    </div>
                }
            </div>
            <nav className='company-nav'>
                <ul className='company-nav-list'>
                    <li className='company-nav-list-item'><p
                        className={`company-link ${location === 'about' ? 'company-link_active' : ''}`}
                        onClick={() => setLocation('about')}>О компании</p></li>
                    <li className='company-nav-list-item'><p
                        className={`company-link ${location === 'products' ? 'company-link_active' : ''}`}
                        onClick={() => setLocation('products')}>Продукция</p></li>
                    {/*<li className='company-nav-list-item'><p className={`company-link ${location === 'gallery' ? 'company-link_active' : ''}`} onClick={() => setLocation('gallery')}>Галерея</p></li>*/}
                </ul>
            </nav>
            {location === 'about' ?
                <div className='company-content-text' dangerouslySetInnerHTML={{ __html: company.content}} />
                : null}
            {location === 'products' ?
                products.length > 0 ?
                <>
                    {/*{isMobile ?*/}
                    {/*<div className='company-filter-modal'>*/}
                    {/*    <FilterModal isCompany={true} isMobile={true}/>*/}
                    {/*</div>*/}
                    {/*:*/}
                    {/*<div className='company-filter-dropdown'>*/}
                    {/*    <FilterDropdownMenu isCompany={true} />*/}
                    {/*</div>}*/}

                    <ProductPager
                        hasMore={hasMore}
                        itemsPerPage={isMobile ? 6 : 8}
                        dataLength={arrayProducts.length}
                        dataList={arrayProducts}
                        nextFetch={loadMore}
                        columns={isMobile ? 2 : 4}
                        rows={isMobile ? 3 : 2}
                        width={isMobile ? 8 : 4}
                        isLoading={isFetching}
                        textFetching={'Загрузка продуктов...'}
                    />
                </>
                : <div style={{margin: '0 auto', fontSize: '30px'}}>Нет продукции</div>
                : null}
            {/*{location === 'gallery' &&*/}
            {/*    <div style={{width: '100%', margin: '0 auto'}}>*/}
            {/*        {screenWidth > 768 ?*/}
            {/*            <ProductPager*/}
            {/*                itemsPerPage='6'*/}
            {/*                dataLength={photos.length}*/}
            {/*                dataList={arrayPhoto}*/}
            {/*                columns='3'*/}
            {/*                rows='2'*/}
            {/*                width='5'*/}
            {/*            />*/}
            {/*            :*/}
            {/*            <div className='company-grid'>*/}
            {/*                {arrayPhoto.map((photo) => {*/}
            {/*                    return (*/}
            {/*                            photo*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*}*/}
            {/*<ModalImageGallery*/}
            {/*    open={modalActive}*/}
            {/*    handleOpen={() => setModalActive(true)}*/}
            {/*    handleClose={() => setModalActive(false)}*/}
            {/*    images={photos}     */}
            {/*    showAsGallery={false}*/}
            {/*    index={modalIndex}*/}
            {/*/>*/}
            <Dimmer active={isLoadingCompany} className='company-dimmer' inverted>
                <Loader active={isLoadingCompany}>Загрузка Компании</Loader>
            </Dimmer>
        </div>
    )
}

export default Company;
