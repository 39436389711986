import React, {useEffect} from 'react';
import '../Catalog/style.css'
import GradientBox from "../../components/GradientBox/gradientBox";
import { isMobile, isBrowser } from 'react-device-detect';
import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {getProductsFilter} from "../Products/reducer";
import {getCategories} from "../Categories/reducer";
import {
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter
} from "../Products/actions";
import ProductLoader from "../../components/ProductLoader";

const Technique = (props) => {

    const {dispatch, routeAction} = props

    const filter = useSelector((state) => getProductsFilter(state.products));

    useEffect(() => {
        if (filter && routeAction === 'PUSH') {
            dispatch(setTagFilter([]))
            dispatch(setCategoryFilter([]))
            dispatch(setDisplayOrderFilter([]))
            dispatch(setSellerFilter([]))
        }
    }, []);


    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])



    const mobileView = (
        <div className="technique-div">
            <div className={'title-text-mobile'}
                 style={{fontSize: '20px', marginTop: '10px', height: '25px'}}>Машины и оборудование</div>
            <br/>
            <div style={{position: 'absolute', top: "-10px", left: '2.4%', paddingRight: '100px'}}>
                <FilterModal isMobile={true} isCompany={true}/>
            </div>
            {/*<ProductLoader*/}
            {/*    category={Number(198)}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Продукты отсутствуют"*/}
            {/*/>*/}
            <p style={{display: "flex", justifyContent: "center"}}>Пока нет товаров в данном сегменте</p>
            <div>
                {/*<GradientBox/>*/}
            </div>
        </div>
    )

    const browserView = (
        <div className="catalog">
            <h1 className={'title-text'} id={'title'}>Машины и оборудование</h1>
            <FilterDropdownMenu isCompany={true}/>
            {/*<ProductLoader*/}
            {/*    category={Number(198)}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Продукты отсутствуют"*/}
            {/*/>*/}
            <p style={{display: "flex", justifyContent: "center"}}>Пока нет товаров в данном сегменте</p>
            {/*<GradientBox/>*/}
        </div>
    )

    return (
        <>
            {isMobile ? mobileView : isBrowser ? browserView : null}
        </>
    )
}

export default Technique;