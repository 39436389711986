import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Image,
    Menu,
    Input,
    Form,
    Icon,
    Button,
    Dropdown,
    Modal,
    Divider,
    Grid,
    GridRow,
    GridColumn,
    FormField,
    Sidebar, SidebarPusher, ModalActions, Dimmer, Loader, Popup
} from 'semantic-ui-react';
import config from '../../config/config';
import {
    openSearch,
    openAuth,
    closeSearch,
    setFormChanged,
    resetFormChanged,
    closeAuth,
    setPopupClose,
    sendEmail,
    CONSUMER_KEY, CONSUMER_SECRET, setCountry, setRegion
} from './actions';
import {dropToken, putTicket} from "../../components/UserLogin/actions";
import {isLocalUserData, isAuthVisible} from './reducer';
import './NavBar.css';
import UserLogin from '../UserLogin/index';
import {updateUserData} from "../UserLogin/actions";
import {getAuthMode, getUserData, getUserDisplayName} from '../UserLogin/reducer';
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import {SEARCH_CONTEXTS, setSearchContext} from "../../views/Search/actions";
import {getToContext} from "../../views/Search/reducer";
import {closeMenu} from "../NavBottomBar/actions";
import { ReactComponent as Logo} from "../../icons/asiaexpo-white-logo.svg";
import { ReactComponent as SearchIcon} from "../../icons/search-icon.svg";
import { ReactComponent as MobileIcon} from "../../icons/mobile-icon.svg";
import { ReactComponent as World} from "../../icons/world-icon.svg";
import { ReactComponent as QuestionIcon } from "../../icons/question-icon.svg";
import { ReactComponent as FAQIcon } from "../../icons/faq-icon.svg";
import { ReactComponent as RuIcon} from "../../icons/ru-icon.svg";
import { ReactComponent as LoginIcon} from "../../icons/login-icon.svg";
import { ReactComponent as CloseIcon} from "../../icons/close-icon.svg";
import { ReactComponent as WhiteCloseIcon} from "../../icons/white-close-icon.svg";
import { ReactComponent as BurgerMenu} from "../../icons/burger-menu-icon.svg";
import UserIcon from "../../icons/user-icon.png";
import UkIcon from "../../icons/uk-icon.png";
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {
    ADDRESS_COUNTRY,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY,
    fetchCounties,
    setAddressFields} from '../AddressCard/actions'
import {getAddressFields, getCountries, isCountriesFetching} from '../AddressCard/reducer';
import _ from "lodash";
import LanguageModal from "../LanguageModal/LanguageModal";
import {getLanguage, setLanguage} from "../LanguageModal/reducer";
import {toastr} from "react-redux-toastr";
import HeartIcon from "../../icons/heart-icon.png";
import {getProducts} from "../../views/Products/reducer";
import {clearCompSearch, setCompSearch} from "../../views/CongressOnline/actions";


export default function NavTopBar(props) {

    const {
        searchVisible,
        dispatch,
        navigate,
        userData,
        userLocation,
        location,
        mixpanel,
        isLoggedIn,
        token,
        localUserData,
        sidebarVisible,
        setSidebarVisible,
        isPageReloaded
    } = props;

    const [ openRegionPopup, setOpenRegionPopup ] = useState(false);
    const [ openRegionNotLogin, setOpenRegionNotLogin ] = useState(false);

    const [ openQuestionPopup, setOpenQuestionPopup ] = useState(false);

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [messageValue, setMessageValue] = useState('');
    const [name, setName] = useState("");
    const [nameDirty, setNameDirty] = useState(false);
    // const [nameError, setNameError] = useState("Поле ввода не может быть пустым");

    const [mail, setMail] = useState("");
    const [mailDirty, setMailDirty] = useState(false);
    const [mailError, setMailError] = useState("Поле ввода не может быть пустым");

    const [textQuestion, setTextQuestion] = useState("");
    const [textDirty, setTextDirty] = useState(false);
    const [textError, setTextError] = useState("Поле ввода не может быть пустым");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const [formValid, setFormValid] = useState(false);
    const [phoneNumberContainsUnderscore, setPhoneNumberContainsUnderscore] = useState(true);

    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
    const userNickName = useSelector((state) => getUserDisplayName(state.userLogin));
    const searchContext = useSelector((state) => getToContext(state.search));
    const countries = useSelector((state) => getCountries(state.address));
    const fields = useSelector((state) => getAddressFields(state.address));
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const localData = useSelector((state) => isLocalUserData(state.navtopbar));
    const products = useSelector((state) => getProducts(state.products));

    const languageDispatch = useDispatch();
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];

    const [searchState, setSearchState] = useState({ search: '', context: searchContext });
    const [formData, setFormData] = useState({
        first_name: userData && userData.billing ? userData.billing.first_name : '',
        last_name: userData && userData.billing ? userData.billing.last_name : '',
        email: userData && userData.billing ? userData.billing.email : '',
        phone: userData && userData.billing ? userData.billing.phone : '',
        phone_country: userData && userData.billing ? userData.billing.phone_country : '',
        country: userData && userData.billing ? userData.billing.country : '',
        city: userData && userData.billing ? userData.billing.city : '',
        state: userData && userData.billing ? userData.billing.state : '',
        id: userData && userData.billing ? userData.billing.id : ''
    });

    // console.log(formData, userData);

    let listPhoneCountries = [];
    if (!isFetching && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }


    const [regionList, setRegionList] = useState({});
    //const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

    const arrayCountries = countries && countries.countries ? Object.values(countries.countries).map(country => ({
        'key': country.iso.toLowerCase(),
        'value': country.iso.toLowerCase(),
        'flag': country.iso.toLowerCase(),
        'text': languageString === 'ru' ? country.country_ru : country.country_en,
        'states': country.states
    })) : [];


    useEffect(() => {
        setSearchState({
            search: '',
            context: searchContext,
        });
    }, [props]);

    useEffect(() => {
        handleCheckCountry(null, {value: formData.country});
        if (!countries || !countries.countries || !countries.countries[fields[ADDRESS_COUNTRY]]) {
            dispatch(fetchCounties());
        }
    }, []);

    useEffect(() => {
        if (mailError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [mailError]);

    const mailHandler = (e) => {
        setMail(e.target.value);
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setMailError("Некорректный email адрес")
        } else {
            setMailError("")
        }
    }

    const nameHandler = (e) => {
        setName(e.target.value);
        // if(!e.target.value) {
        //     setNameError("Поле ввода не может быть пустым");
        // }
        // else {
        //     setNameError("");
        // }
    }

    const textHandler = (e) => {
        // setTextError(e.target.value);
        setMessageValue(e.target.value);
        // if(!e.target.value) {
        //     setTextError("Поле ввода не может быть пустым");
        // }
        // else {
        //     setTextError("");
        // }
    }

    const createEmailTiket = (to, subject, message, email, phone, who) => {
        setIsLoading(true);
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

    }

    const handleSubmitQuestion = () => {
        if (messageValue && phoneNumber && userName && mail) {
            dispatch(putTicket(token, 'Вопрос через VENUE от ' + ' ' + userName, messageValue, userName, 'a.burenko@feedtech.su', mail, phoneNumber ))
            setOpenQuestionPopup(false)
            setMessageValue('')
            setMail('')
            setPhoneNumber('')
        }
        // else if (!token && messageValue && phoneNumber && userName && mail) {
        //     toastr.error(`Чтобы отправить вопрос, пожалуйста, войдите в систему`)
        //     setOpenQuestionPopup(false)
        //     setMessageValue('')
        //     setMail('')
        //     setPhoneNumber('')
        // }

        if (!phoneNumber || !userName || !mail || !messageValue) {
                toastr.warning(`Пожалуйста, заполните все поля`)
        }

    }

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'mail':
                setMailDirty(true);
                break;
            case 'name':
                setNameDirty(true);
                break;
            case 'question':
                setTextDirty(true);
                break;
        }
    }

    const handleChangeField = (fieldName, value) => {
        if (value.includes('_')) {
            setPhoneNumberContainsUnderscore(true);
        } else {
            setPhoneNumberContainsUnderscore(false);
        }
        setPhoneNumber(value);
        // console.log('phone -', phoneNumber);
    }

    function handleSubmit() {
        setSidebarVisible(false)
        dispatch(closeSearch());
        dispatch(setSearchContext(searchState.context));
        navigate(`/search/${searchState.search}`, {state: {doQuery: true}});
        if (mixpanel)
            mixpanel.track('Search Confirm', { ...userLocation, ...userData, ...searchState });
    }

    function setSearch(e) {
        setSearchState({ ...searchState, search: e.target.value });
    }

    function handleOpenSearch() {
        dispatch(openSearch());
        if (mixpanel)
            mixpanel.track('Search Open', { ...userLocation, ...userData, ...searchState });
        dispatch(closeMenu());
    }

    function handleOpenAuth() {
        dispatch(openAuth());
        if (mixpanel)
            mixpanel.track('Auth Open Form', { ...userLocation, ...userData });
    }

    function handleSelectContext(e, data) {
        setSearchState({ ...searchState, context:data.value });
    }



    function goBack() {
        navigate( -1 );
    }

    function handleChangeSearch(e) {
        // console.log('handleChangeSearch' ,e);

        setSearchState({ search: e.target.value });
        if (e.target.value.length >= 3) {
            dispatch(setCompSearch(e.target.value));
        } else if (e.target.value.length < 3) {
            dispatch(clearCompSearch());
        }
    }

    const searchForm = () => (
        <>
            <BrowserView>
                {location.pathname === '/congress-online'
                    ? <Form onSubmit={handleSubmit} className="search-form-congress">
                        <input
                            type='search'
                            placeholder="Поиск по поставщикам"
                            name="search"
                            className="search-field-congress"
                            value={searchState.search}
                            onChange={handleChangeSearch}
                            autoFocus={false}
                            autoComplete="off"

                        >
                            {/*<input className="search-field"/>*/}
                            {/*<Button className="search-button" onClick={() => closeSearchInput()}  type="submit" icon="search"/>*/}
                        </input>
                    </Form>
                    : <Form onSubmit={handleSubmit} className="search-form">
                    <Dropdown
                        className="search-dropdown"
                        options={SEARCH_CONTEXTS}
                        value={searchState.context}
                        onChange={handleSelectContext}
                    />
                    <Input
                        type='search'
                        placeholder='Поиск'
                        name="search"
                        className="search"
                        value={searchState.search}
                            onChange={setSearch}
                        // autoFocus={true}
                    >

                        <input className="search-field"/>

                        <Button  className="search-button" type="submit" icon="search" />
                    </Input>
                </Form>}
            </BrowserView>

            <MobileView>
                {location.pathname === '/congress-online'
                    ? <Form onSubmit={handleSubmit} className="search-form-congress mobile">
                        <input
                            type='search'
                            placeholder="Поиск по поставщикам"
                            name="search"
                            className="search-field-congress"
                            value={searchState.search}
                            onChange={handleChangeSearch}
                            autoFocus={false}
                            autoComplete="off"

                        >
                            {/*<input className="search-field"/>*/}
                            {/*<Button className="search-button" onClick={() => closeSearchInput()}  type="submit" icon="search"/>*/}
                        </input>
                    </Form>
                    : <Form onSubmit={handleSubmit} className="search-form mobile">
                        <Dropdown
                            className="search-dropdown"
                            options={SEARCH_CONTEXTS}
                            value={searchState.context}
                            onChange={handleSelectContext}
                        />
                        <Input
                            type='search'
                            placeholder='Поиск'
                            name="search"
                            className="search"
                            value={searchState.search}
                            onChange={setSearch}
                            // autoFocus={true}
                        >

                            <input className="search-field"/>

                            <Button  className="search-button" type="submit" icon="search" />
                        </Input>
                    </Form>}
            </MobileView>
        </>

    );
    const searchFormHeader = () => (
        <>
            <MobileView>
                {/*<Form onSubmit={handleSubmit} className="search-form mobile header-search">*/}
                {/*    <Input*/}
                {/*        type='search'*/}
                {/*        placeholder='Поиск'*/}
                {/*        name="search"*/}
                {/*        className="search"*/}
                {/*        value={searchState.search}*/}
                {/*        onChange={setSearch}*/}
                {/*        // autoFocus={true}*/}
                {/*    >*/}
                {/*        <input className="search-field header-search-field"/>*/}
                {/*        <Button  className="search-button" type="submit" icon="search" />*/}
                {/*    </Input>*/}
                {/*</Form>*/}
                {location.pathname === '/congress-online'
                    ? <Form onSubmit={handleSubmit} className="search-form-congress mobile header-search">
                        <input
                            type='search'
                            placeholder="Поиск по поставщикам"
                            name="search"
                            className="search-field-congress"
                            value={searchState.search}
                            onChange={handleChangeSearch}
                            autoFocus={false}
                            autoComplete="off"

                        >
                            {/*<input className="search-field"/>*/}
                            {/*<Button className="search-button" onClick={() => closeSearchInput()}  type="submit" icon="search"/>*/}
                        </input>
                    </Form>
                    : <Form onSubmit={handleSubmit} className="search-form">
                        <Dropdown
                            className="search-dropdown"
                            options={SEARCH_CONTEXTS}
                            value={searchState.context}
                            onChange={handleSelectContext}
                        />
                        <Input
                            type='search'
                            placeholder='Поиск'
                            name="search"
                            className="search"
                            value={searchState.search}
                            onChange={setSearch}
                            // autoFocus={true}
                        >

                            <input className="search-field"/>

                            <Button  className="search-button" type="submit" icon="search" />
                        </Input>
                    </Form>}
            </MobileView>
        </>
    );

    const checkFormChanges = () => {
        const formFields = ['first_name', 'last_name', 'email', 'phone_country' ,'phone', 'country', 'city' ];
        const isAvatarChanged = formData['avatar'] !== userData['avatar'];
        const isFieldChanged = userData && userData.billing && formFields.some(field => formData[field] !== userData.billing[field]);
        if (isFieldChanged || isAvatarChanged) {
            dispatch(setFormChanged());
        } else {
            dispatch(resetFormChanged());
        }
    }

    const changeRegion = (value) => {
        const searchCountry = arrayCountries.find(country => country.value === value.toLowerCase());
        const regions = searchCountry && searchCountry['states'] ? Object.values(searchCountry['states']).map((region) => ({text: languageString === 'ru' ? region.name : region.name_en, value: region.code.toString()})) : [];
        // console.log(regions, 'РЕГИОНЫ', searchCountry, 'страны');
        setRegionList(regions)
    }

    const handleCheckCountry = (e, {value}) => {
            setFormData({
                ...formData,
                country: value
            });
            changeRegion(value);
            checkFormChanges();
    }

    const handleCityChange = (e, { value }) => {
            setFormData({
                ...formData,
                city: value,
                state: value,
            });
            checkFormChanges();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        validate(name, value);
        setFormData({
            ...userData,
            [e.target.name]: e.target.value,
        });
        checkFormChanges();
    }

    const submitUserData = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const query = JSON.stringify({
            billing: {
                // first_name: userData && userData.billing ? formData.first_name : '',
                // last_name: userData && userData.billing ? formData.last_name : '',
                // email: userData && userData.billing ? formData.email : '',
                // phone: userData && userData.billing ? formData.phone : '',
                // phone_country: userData && userData.billing ? formData.phone_country : '',
                country: userData && userData.billing ? formData.country.toUpperCase() : '',
                city: userData && userData.billing ? formData.city : '',
                state: userData && userData.billing ? formData.state : '',
            }
        })
        dispatch(updateUserData(props.token, userData.id, query, false, languageString))
            .then(() => {
                setIsLoading(false);
                setOpenRegionPopup(false)
            })
            .catch((error) => {
                setIsLoading(false);
            });
        dispatch(resetFormChanged());

    }


    const validate = (fieldName, value) => {
        let fieldErrors = {};
        if (fieldName === 'first_name') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Имя обязательно для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'last_name') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Фамилия обязательна для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        // if (fieldName === 'email') {
        //     if (!value) {
        //         fieldErrors = { [fieldName]: 'Email обязателен для заполнения'};
        //     } else if (!isValidEmail(value)) {
        //         fieldErrors = { [fieldName]: 'Некорректный email'};
        //     } else {
        //         fieldErrors = { [fieldName]: ''};
        //     }
        // }
        if (fieldName === 'phone') {
            if (!value) {
                fieldErrors = { [fieldName]: 'Телефон обязателен для заполнения'};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
    }

    const ModalRegion = () => {
        return (
            <Modal className='venue-modal'
                   centered={false}
                   open={openRegionPopup}
                   onClose={() => setOpenRegionPopup(false)}
            >
                <Modal.Content className='venue-modal-content'>
                    <div className='venue-title-text'>Выберите свой регион</div>
                    <CloseIcon className="venue-modal-close-icon" />
                    <Form onSubmit={submitUserData}>
                        <FormField className='venue-field'>
                            <div className='venue-container'>
                                <Dropdown
                                    fluid
                                    search
                                    name='country'
                                    selection
                                    className='venue-input'
                                    placeholder='Страна'
                                    value={formData.country.toLowerCase()}
                                    options={arrayCountries}
                                    onChange={handleCheckCountry}
                                />
                            </div>
                        </FormField>
                        <FormField className='venue-field'>
                            <div className='venue-container'>
                                <Dropdown
                                    fluid
                                    search
                                    className='venue-input'
                                    name='state'
                                    placeholder='Регион'
                                    selection
                                    options={regionList}
                                    value={formData.state}
                                    onChange={handleCityChange}
                                    required
                                    basic={true}
                                />
                            </div>
                        </FormField>
                        <Button className='venue-modal-btn' type='submit' onClick={() => setOpenRegionPopup(false)}>Выбрать</Button>
                    </Form>
                </Modal.Content>
            </Modal>
        )
    }



    const handleLanguageChange = (newLanguage)=>{
        languageDispatch(setLanguage(newLanguage))
    }

    const selectedState = userData && userData.billing ? userData.billing.state : null;
    const countryData = userData && userData.billing ? countries.countries[userData.billing.country] : null;

    const desktopMenu = () => (
        <>
            <BrowserView>
                <Grid className="venue-main-menu desktop">
                    <GridRow className="venue-top-submenu" verticalAlign="middle">
                        {token && userData && userData.billing ? (
                            <GridColumn width={4} className="venue-region" onClick={() => setOpenRegionPopup(true)}>
                                Ваш регион:
                                <b>
                                    &nbsp;
                                    {countryData
                                        ?  languageString === 'ru' ? countryData.country_ru : countryData.country_en
                                        : "Не указан"}
                                    {`, ${languageString !== 'ru'
                                        ? countryData.states.find(state => state.code == selectedState)?.name_en
                                        : countryData.states.find(state => state.code == selectedState)?.name_ru}`}
                                </b>
                            </GridColumn>
                        ) : (
                            <GridColumn width={4} className="venue-region" onClick={() => toastr.error(`Для выбора региона, пожалуйста, войдите в систему`)}>
                                Ваш регион: <b>Россия, Краснодарский край</b>
                            </GridColumn>)}
                        <GridColumn width={4} className="venue-search">
                            { searchForm() }
                        </GridColumn>
                        <GridColumn width={8} className="venue-menu-items">
                            <div className="venue-menu-item" onClick={() => navigate(`/favorite`)}>
                                <img src={HeartIcon} height='20px' className='venue-bottom-bar-icon'/>
                                Избранное
                            </div>
                            <div className="venue-menu-item" onClick={() => setOpenQuestionPopup(true)}>
                                <QuestionIcon className={'navbar-icon'}/>
                                Задать вопрос
                            </div>
                            {token && userData && userData.billing ? (
                                <Popup
                                    trigger={
                                        <div className="venue-menu-item">
                                            <img src={UserIcon} height='20px'/>
                                            {userData.billing.first_name}
                                        </div>}
                                    position='bottom center'
                                    basic
                                    hoverable
                                    flowing
                                    className='user-log-out-popup'

                                >
                                    <div className='user-log-out-text'>{userData.billing.first_name} {userData.billing.last_name}</div>
                                    <Divider fitted style={{margin: '5px 0'}}/>
                                    <Button className='user-log-out-btn' onClick={() => {(
                                        dispatch(dropToken()), location.pathname === '/account' ? navigate(`/`) : null)
                                    }}>Выйти</Button>
                                </Popup>

                            ) : (
                                <div className="venue-menu-item" onClick={handleOpenAuth}>
                                    <LoginIcon className="shop-icon"/>
                                    Вход/Регистрация
                                    <UserLogin showModal={authVisible} {...props}/>
                                </div>)}
                            <LanguageModal
                                triggerButton={<div className="venue-menu-item">
                                    <World className={'navbar-icon'}/>
                                    {languageString.toUpperCase()}
                                </div>}
                                onLanguageChange={handleLanguageChange}
                                currentLanguage={languageString}
                            />
                        </GridColumn>
                    </GridRow>
                    <GridRow className="venue-bottom-submenu" verticalAlign="middle">
                        <GridColumn width={3} className="venue-region">
                            <Logo onClick={() => navigate(`/`)}/>
                        </GridColumn>
                        <GridColumn width={12} className="venue-menu-items">
                            <div className="venue-menu-item-bottom" onClick={() => navigate(`/catalog`)}>
                                Каталог
                            </div>
                            <div className="venue-menu-item-bottom" onClick={() => navigate(`/technique`)}>
                                Машины и оборудование
                            </div>
                            <div className="venue-menu-item-bottom" onClick={() => navigate(`/services`)}>
                                Услуги
                            </div>
                            <div className="venue-menu-item-bottom" onClick={() => navigate(`/congress-online`)}>
                                Конгресс-онлайн
                            </div>
                            {/*<div className="venue-menu-item-bottom" onClick={() => navigate(`/blog`)}>*/}
                            {/*    Блог*/}
                            {/*</div>*/}
                            {/*<div className="venue-menu-item-bottom" onClick={() => navigate(`/customer-offers`)}>*/}
                            {/*    Предложения покупателей*/}
                            {/*</div>*/}

                        </GridColumn>
                    </GridRow>
                </Grid>
                <Modal
                    className='venue-modal'
                    centered={false}
                    open={openQuestionPopup}
                    onClose={() => setOpenQuestionPopup(false)}
                >
                    <Modal.Content className="venue-modal-content">
                        <div className="venue-modal-text venue-title-text">Задайте нам любой интересующий Вас вопрос!</div>
                        <div className='venue-modal-text venue-mini-text'>*Вопрос должен касаться данной площадки.</div>
                        <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenQuestionPopup(false)}/>

                        {/*{(nameDirty) && <div style={{color: 'red'}}></div>}*/}
                        <Input
                            className='venue-modal-placeholder'
                            type='text'
                            name='name'
                            placeholder='Ваше имя'
                            transparent
                            onChange={e => {nameHandler(e); handleUserNameChange(e)}}
                            onBlur={e => blurHandler(e)}
                        />

                        <PhoneCountryMask
                            extraClass="edit-input-phone edit-input"
                            name="phone"
                            countries={listPhoneCountries}
                            country="RU"
                            loading={isFetching}
                            phone={phoneNumber}
                            handleChangeField={handleChangeField}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={true}
                        />
                        <Input
                            className='venue-modal-placeholder'
                            type='text'
                            name='mail'
                            placeholder='Электронная почта'
                            transparent
                            onChange={e => mailHandler(e)}
                            onBlur={e => blurHandler(e)}
                        />
                        {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}

                        {/*{(textDirty && textError) && <div style={{color: 'red'}}>{textError}</div>}*/}
                        <Input
                            className='venue-modal-placeholder'
                            type='text'
                            name='question'
                            placeholder='Ваш вопрос'
                            transparent
                            onChange={e => {textHandler(e)}}
                            onBlur={e => blurHandler(e)}
                        />

                        <Button className="venue-modal-btn" onClick={handleSubmitQuestion}>Отправить</Button>

                    </Modal.Content>
                    <Dimmer inverted active={isLoading} className={'venue-dimmer'}>
                        <Loader active={isLoading}>Отправка сообщения</Loader>
                    </Dimmer>
                </Modal>

                <Modal className='venue-modal'
                       centered={false}
                       open={openRegionPopup}
                       onClose={() => setOpenRegionPopup(false)}
                >
                    <Modal.Content className='venue-modal-content'>
                        <div className='venue-title-text'>Выберите свой регион</div>
                        <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenRegionPopup(false)}/>
                        <Form onSubmit={submitUserData}>
                            <FormField className='venue-field'>
                                <div className='venue-container'>
                                    <Dropdown
                                        fluid
                                        search
                                        name='country'
                                        selection
                                        className='venue-input'
                                        placeholder='Страна'
                                        value={formData.country.toLowerCase()}
                                        options={arrayCountries}
                                        onChange={handleCheckCountry}
                                    />
                                </div>
                            </FormField>
                            <FormField className='venue-field'>
                                <div className='venue-container'>
                                    <Dropdown
                                        fluid
                                        search
                                        className='venue-input'
                                        name='state'
                                        placeholder='Регион'
                                        selection
                                        options={regionList}
                                        value={formData.state}
                                        onChange={handleCityChange}
                                        required
                                        basic={true}
                                    />
                                </div>
                            </FormField>
                            <Button className='venue-modal-btn' type='submit'>Выбрать</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
            </BrowserView>

            <MobileView>
                <Grid className="venue-main-menu desktop header-mobile">
                    <GridRow className="venue-top-submenu mobile" verticalAlign="middle">
                        {!searchVisible ? <GridColumn width={6} className="venue-region">
                            <Logo className="logo region" onClick={() => navigate(`/`)}/>
                        </GridColumn> : null}
                        <GridColumn width={10} className="venue-menu-items">
                            {token && userData && userData.billing ? (
                                !searchVisible ? <Popup
                                    trigger={
                                        <div className="venue-menu-item">
                                            <img src={UserIcon} height='20px'/>
                                            {userData.billing.first_name}
                                        </div>}
                                    position='bottom center'
                                    basic
                                    hoverable
                                    flowing
                                    className='user-log-out-popup'
                                    on='click'
                                >
                                    <div className='user-log-out-text'>{userData.billing.first_name} {userData.billing.last_name}</div>
                                    <Divider fitted style={{margin: '5px 0'}}/>
                                    <Button className='user-log-out-btn' onClick={() => {(
                                        dispatch(dropToken()), location.pathname === '/account' ? navigate(`/`) : null)
                                    }}>Выйти</Button>
                                </Popup> : null ) : (
                                !searchVisible ? <div className="venue-menu-item" onClick={handleOpenAuth}>
                                    <LoginIcon className="shop-icon"/>
                                    Вход
                                    <UserLogin showModal={authVisible} {...props}/>
                                </div> : null
                            ) }
                            {!searchVisible ? <div className="venue-menu-item">
                                 <LanguageModal
                                    triggerButton={<World className={'navbar-icon'}/>}
                                    onLanguageChange={handleLanguageChange}
                                    currentLanguage={languageString}
                                />
                                {languageString.toUpperCase()}
                            </div> : null}
                            {!searchVisible ? <BurgerMenu className='venue-burger-menu' onClick={() => setSidebarVisible(true)}/> : null}
                            {searchVisible ? <div className='venue-search mobile'>{searchFormHeader()}</div> : null}
                            <Sidebar className='venue-header-sidebar'
                                     width="very wide"
                                     visible={sidebarVisible}
                                     direction="right"
                                     animation='overlay'
                                     onHide={() => setSidebarVisible(false)}

                            >
                                {token && userData && userData.billing ? (
                                    <GridColumn width={4} className="venue-region venue-sidebar-item"
                                                onClick={() => {
                                                    setOpenRegionPopup(true);
                                                    setSidebarVisible(false)
                                                }}>
                                        Ваш регион: <b>
                                        &nbsp;
                                        {countryData
                                            ? languageString === 'ru' ? countryData.country_ru : countryData.country_en
                                            : "Не указан"}
                                        {`, ${languageString !== 'ru'
                                            ? countryData.states.find(state => state.code == selectedState)?.name
                                            : countryData.states.find(state => state.code == selectedState)?.name_ru}`}</b>
                                        <ModalRegion/>
                                    </GridColumn>
                                ) : (
                                    <GridColumn width={4} className="venue-region venue-sidebar-item"
                                                onClick={() => toastr.error(`Для выбора региона, пожалуйста, войдите в систему`)}>
                                        Ваш регион: <b>Россия, <br/>Краснодарский край</b></GridColumn>)}
                                <div className="sidebar-search">
                                    {searchForm()}
                                </div>
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate('/catalog');
                                    setSidebarVisible(false)
                                }}>
                                    Каталог
                                </div>
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate('/technique');
                                    setSidebarVisible(false)
                                }}>
                                    Машины и оборудование
                                </div>
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate('/services');
                                    setSidebarVisible(false)
                                }}>
                                    Услуги
                                </div>
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate('/catalog/aquaculture-and-fisheries');
                                    setSidebarVisible(false)
                                }}>
                                    Аквакультура и рыболовство
                                </div>
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate(`/congress-online`);
                                    setSidebarVisible(false)
                                }}>
                                    Конгресс-онлайн
                                </div>
                                {/*<div className="venue-sidebar-item" onClick={() => {*/}
                                {/*    navigate(`/blog`);*/}
                                {/*    setSidebarVisible(false)*/}
                                {/*}}>*/}
                                {/*    Блог*/}
                                {/*</div>*/}
                                {/*<div className="venue-sidebar-item" onClick={() => {*/}
                                {/*    navigate(`/customer-offers`);*/}
                                {/*    setSidebarVisible(false)*/}
                                {/*}}>*/}
                                {/*    Предложения покупателей*/}
                                {/*</div>*/}
                                <div className="venue-sidebar-item" onClick={() => {
                                    navigate(`/favorite`);
                                    setSidebarVisible(false)
                                }}>
                                    Избранное
                                </div>
                                {/*<div className="venue-sidebar-item" onClick={() => {*/}
                                {/*    navigate(`/cart`);*/}
                                {/*    setSidebarVisible(false)*/}
                                {/*}}>*/}
                                {/*    Корзина*/}
                                {/*</div>*/}
                                <WhiteCloseIcon className="venue-modal-close-icon"
                                                onClick={() => setSidebarVisible(false)}/>
                                <br/>
                                <Logo className="venue-sidebar-item" onClick={() => {
                                    setSidebarVisible(false);
                                    navigate(`/`)
                                }}/>
                                <div className="venue-menu-item venue-sidebar-item">
                                    <MobileIcon className='navbar-icon'/>
                                    <a href='tel:88005553535' className='sidebar-phone'
                                       onClick={() => setSidebarVisible(false)}>
                                        8 800 555 35 35
                                    </a>
                                </div>

                                <div className="venue-menu-item venue-sidebar-item" onClick={() => {
                                    setSidebarVisible(false);
                                    setOpenQuestionPopup(true)
                                }}>
                                    <QuestionIcon className={'navbar-icon'}/>
                                    Задать вопрос
                                </div>
                                <div className="venue-menu-item venue-sidebar-item" onClick={() => {
                                    setSidebarVisible(false);
                                    navigate(`/faq`);
                                }}>
                                    <FAQIcon className={'navbar-icon'}/>
                                    Справка
                                </div>
                            </Sidebar>
                        </GridColumn>
                    </GridRow>
                </Grid>
                <Modal
                    className={'venue-modal'}
                    centered={false}
                    open={openQuestionPopup}
                    onClose={() => setOpenQuestionPopup(false)}
                >
                    <Modal.Content className="venue-modal-content">
                    <div className={"venue-modal-text venue-title-text"}>Задайте нам любой интересующий Вас вопрос!</div>
                    <div className={'venue-modal-text venue-mini-text'}>*Вопрос должен касаться данной площадки.</div>
                    <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenQuestionPopup(false)}/>

                    {/*{(nameDirty) && <div style={{color: 'red'}}></div>}*/}
                    <Input
                        className='venue-modal-placeholder'
                        type='text'
                        name='name'
                        placeholder='Ваше имя'
                        transparent
                        onChange={e => {nameHandler(e); handleUserNameChange(e)}}
                        onBlur={e => blurHandler(e)}
                    />

                    <PhoneCountryMask
                        extraClass="edit-input-phone edit-input"
                        name="phone"
                        countries={listPhoneCountries}
                        country="RU"
                        loading={isFetching}
                        phone={phoneNumber}
                        handleChangeField={handleChangeField}
                        nameCountry={ADDRESS_PHONE_COUNTRY}
                        namePhone={ADDRESS_PHONE}
                        fluid={true}
                    />
                    <Input
                        className='venue-modal-placeholder'
                        type='text'
                        name='mail'
                        placeholder='Электронная почта'
                        transparent
                        onChange={e => mailHandler(e)}
                        onBlur={e => blurHandler(e)}
                    />
                    {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}

                    {/*{(textDirty && textError) && <div style={{color: 'red'}}>{textError}</div>}*/}
                    <Input
                        className='venue-modal-placeholder'
                        type='text'
                        name='question'
                        placeholder='Ваш вопрос'
                        transparent
                        onChange={e => {textHandler(e)}}
                        onBlur={e => blurHandler(e)}
                    />

                    <Button className="venue-modal-btn" onClick={handleSubmitQuestion}>Отправить</Button>

                </Modal.Content>
                <Dimmer inverted active={isLoading} className={'venue-dimmer'}>
                    <Loader active={isLoading}>Отправка сообщения</Loader>
                </Dimmer>
            </Modal>
            </MobileView>
        </>
    );

    return (
        <>
            {desktopMenu()}
        </>
    );
}
