import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {
    Button, Dimmer, Divider, Dropdown, Form,
    Grid,
    GridColumn,
    GridRow,
    Icon, Input, Label, Loader, Menu, Modal, Popup,
} from 'semantic-ui-react';
import {closeMenu, openMenu} from './actions';
import { ReactComponent as QuestionIcon } from "../../icons/question-icon.svg";
import { ReactComponent as MobileIcon} from "../../icons/mobile-icon.svg"
import { ReactComponent as LoginIcon} from "../../icons/login-icon.svg";
import { ReactComponent as VkIcon } from "../../icons/vk-icon.svg";
import { ReactComponent as YoutubeIcon} from "../../icons/yt-icon.svg"
import { ReactComponent as TgIcon } from "../../icons/tg-icon.svg";
import { ReactComponent as CloseIcon} from "../../icons/close-icon.svg";
import { ReactComponent as HomeIcon} from "../../icons/home-logo.svg";
import {BrowserView, MobileView} from "react-device-detect";

import UserIcon from "../../icons/user-icon.png";
import HeartIcon from "../../icons/heart-icon.png"
import CatalogIcon from "../../icons/catalog-icon.png"
import { ReactComponent as SearchIcon} from "../../icons/search-icon.svg";
import {getUserDisplayName} from "../UserLogin/reducer";
import UserLogin from "../UserLogin";
import {isAuthVisible} from "../NavTopBar/reducer";
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY} from "../AddressCard/actions";
import {dropToken, putTicket} from "../UserLogin/actions";
import {toastr} from "react-redux-toastr";
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {closeSearch, openAuth, openSearch} from "../NavTopBar/actions";
import {getToContext} from "../../views/Search/reducer";
import {SEARCH_CONTEXTS, setSearchContext} from "../../views/Search/actions";

export default function NavBottomBar (props) {

    const [openPopup, setOpenPopup] = useState(false);
    const {menuVisible, dispatch, navigate, token, userData, mixpanel, userLocation} = props;
    const userNickName = useSelector((state) => getUserDisplayName(state.userLogin));
    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));

    const [isLoading, setIsLoading] = useState(false);

    const [messageValue, setMessageValue] = useState('');
    const [name, setName] = useState("");
    const [nameDirty, setNameDirty] = useState(false);
    const [nameError, setNameError] = useState("Поле ввода не может быть пустым");

    const [mail, setMail] = useState("");
    const [mailDirty, setMailDirty] = useState(false);
    const [mailError, setMailError] = useState("Поле ввода не может быть пустым");

    const [textQuestion, setTextQuestion] = useState("");
    const [textDirty, setTextDirty] = useState(false);
    const [textError, setTextError] = useState("Поле ввода не может быть пустым");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const [formValid, setFormValid] = useState(false);
    const [phoneNumberContainsUnderscore, setPhoneNumberContainsUnderscore] = useState(true);

    const countries = useSelector((state) => getCountries(state.address));
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const searchContext = useSelector((state) => getToContext(state.search));

    const [searchState, setSearchState] = useState({});

    useEffect(() => {
        setSearchState({
            search: '',
            context: searchContext,
        });
    }, [props]);

    const searchForm = () => (
        <>
            <BrowserView>
                <Form onSubmit={handleSubmit} className="search-form">
                    <Input
                        type='search'
                        placeholder='Поиск'
                        name="search"
                        className="search"
                        value={searchState.search}
                        onChange={setSearch}
                        // autoFocus={true}
                    >
                        <input className="search-field"/>
                        <Button className="search-button" type="submit" icon="search" />
                    </Input>
                </Form>
            </BrowserView>

            <MobileView>
                <Form onSubmit={handleSubmit} className="search-form mobile">
                    <Input
                        type='search'
                        placeholder='Поиск'
                        name="search"
                        className="search"
                        value={searchState.search}
                        onChange={setSearch}
                        // autoFocus={true}
                    >
                        <input className="search-field"/>
                        <Button  className="search-button" type="submit" icon="search" />
                    </Input>
                </Form>
            </MobileView>
        </>
    );

    function handleSubmit() {
        dispatch(closeSearch());
        dispatch(setSearchContext(searchState.context));
        navigate(`/search/${searchState.search}`, {state: {doQuery: true}});
        if (mixpanel)
            mixpanel.track('Search Confirm', { ...userLocation, ...userData, ...searchState });
    }

    function setSearch(e) {
        setSearchState({ ...searchState, search: e.target.value });
    }

    function handleOpenSearch() {
        dispatch(openSearch());
        if (mixpanel)
            mixpanel.track('Search Open', { ...userLocation, ...userData, ...searchState });
        dispatch(closeMenu());
    }


    function showSidebar(e) {
    e.stopPropagation();
    dispatch( menuVisible ? closeMenu() : openMenu());
  }

    const mailHandler = (e) => {
        setMail(e.target.value);
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setMailError("Некорректный email адрес")
        } else {
            setMailError("")
        }
    }

    let listPhoneCountries = [];
    if (!isFetching && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }

    const nameHandler = (e) => {
        setName(e.target.value);
        if(!e.target.value) {
            setNameError("Поле ввода не может быть пустым");
        }
        else {
            setNameError("");
        }
    }

    const textHandler = (e) => {
        setTextError(e.target.value);
        setMessageValue(e.target.value);
        if(!e.target.value) {
            setTextError("Поле ввода не может быть пустым");
        }
        else {
            setTextError("");
        }
    }

    const createEmailTiket = (to, subject, message, email, phone, who) => {
        setIsLoading(true);
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

    }

    const handleSubmitQuestion = () => {
        if (token && messageValue && phoneNumber && userName && mail) {

            dispatch(putTicket(token, 'Вопрос через VENUE от ' + ' ' + userName, messageValue, userName, 'a.burenko@feedtech.su', mail, phoneNumber ))

            setOpenPopup(false)
            setMessageValue('')
            setMail('')
            setPhoneNumber('')
        }
        // else if (!token && messageValue && phoneNumber && userName && mail) {
        //     toastr.error(`Чтобы отправить вопрос, пожалуйста, войдите в систему`)
        //     setOpenPopup(false)
        //     setMessageValue('')
        //     setMail('')
        //     setPhoneNumber('')
        // }

        if (!phoneNumber || !userName || !mail || !messageValue) {
            toastr.warning(`Пожалуйста, заполните все поля`)
        }

    }

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'mail':
                setMailDirty(true);
                break;
            case 'name':
                setNameDirty(true);
                break;
            case 'question':
                setTextDirty(true);
                break;
        }
    }

    const handleChangeField = (fieldName, value) => {
        if (value.includes('_')) {
            setPhoneNumberContainsUnderscore(true);
        } else {
            setPhoneNumberContainsUnderscore(false);
        }
        setPhoneNumber(value);
        // console.log('phone -', phoneNumber);
    }

    function handleOpenAuth() {
        dispatch(openAuth());
        if (mixpanel)
            mixpanel.track('Auth Open Form', { ...userLocation, ...userData });
    }

  return (
      <>
          <BrowserView>
          <div className='venue-main-menu-bottom desktop'>
              <div className='venue-main-menu container-columns'>
                  <div className='venue-main-menu column'>
                      Основные разделы
                      <div className='venue-main-menu column-container'>
                          <span className='venue-main-menu column-text' onClick={() => navigate(`/congress-online`)}>Конгресс онлайн</span>
                          <span className='venue-main-menu column-text' onClick={() => navigate(`catalog/plant-production`)}>Сельское хозяйство</span>
                          <span className='venue-main-menu column-text' onClick={() => navigate(`catalog/animal-husbandry`)}>Кормовые добавки и ветеринария</span>
                          <span className='venue-main-menu column-text' onClick={() => navigate(`/technique`)}>Машины и оборудование</span>
                          <span className='venue-main-menu column-text' onClick={() => navigate(`/catalog/aquaculture-and-fisheries`)}>Аквакультура и рыболовство</span>
                          {/*<span className='venue-main-menu column-text' onClick={() => navigate(`/blog`)}>Блог</span>*/}
                          {/*<span className='venue-main-menu column-text' onClick={() => navigate(`/customer-offers`)}>Предложения покупателей</span>*/}
                      </div>
                  </div>
                  <div className='venue-main-menu column'>
                      Компания
                      <div className='venue-main-menu column-container'>
                          {/*<span className='venue-main-menu column-text'>О нас</span>*/}
                          {/*<span className='venue-main-menu column-text'>Экспонентам</span>*/}
                          {/*<span className='venue-main-menu column-text'>Документы</span>*/}
                          <span className='venue-main-menu column-text' onClick={() => navigate('/faq')}>Справка</span>
                      </div>
                  </div>
                  <div className='venue-main-menu column'>
                      Контакты
                      <div className='venue-main-menu column-container'>
                          <a href='mailto:venue@asiaexpo.space' className='venue-main-menu column-text'>Почта для связи - venue@asiaexpo.space</a>
                          <a href='mailto:press@asiaexpo.space' className='venue-main-menu column-text'>Почта для PR - press@asiaexpo.space</a>
                          <a href='mailto:venue@asiaexpo.space' className='venue-main-menu column-text'>Почта для статей - venue@asiaexpo.space</a>
                      </div>
                      <div className='venue-main-menu container-contacts'>
                          <MobileIcon className='navbar-icon'/>
                          <a href='tel:88005553535' className='venue-main-menu column-text-contacts'>
                              8 800 555 35 35
                          </a>
                          <br/>
                          <div className='pointer-container' onClick={() => setOpenPopup(true)}>
                          <QuestionIcon className='navbar-icon'/>
                          <span className='venue-main-menu column-text-contacts' >Задать вопрос</span>
                          </div>
                      </div>
                  </div>
                  <div className='venue-main-menu column-links'>
                      <a href='https://vk.com/asiaexpo_ru' className='link venue-bottom'>
                          <VkIcon/>
                      </a>
                      <a href='https://t.me/asia_expo' className='link venue-bottom'>
                          <TgIcon/>
                      </a>
                      <a href='https://www.youtube.com/@asiaexpo_ru' className='link venue-bottom'>
                          <YoutubeIcon/>
                      </a>
                  </div>
              </div>
          </div>
              <Modal
                  className='venue-modal'
                  centered={false}
                  open={openPopup}
                  onClose={() => setOpenPopup(false)}
              >
                  <Modal.Content className="venue-modal-content">
                      <div className="venue-modal-text venue-title-text">Задайте нам любой интересующий Вас вопрос!</div>
                      <div className='venue-modal-text venue-mini-text'>*Вопрос должен касаться данной площадки.</div>
                      <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenPopup(false)}/>

                      {/*{(nameDirty) && <div style={{color: 'red'}}></div>}*/}
                      <Input
                          className='venue-modal-placeholder'
                          type='text'
                          name='name'
                          placeholder='Ваше имя'
                          transparent
                          onChange={e => {nameHandler(e); handleUserNameChange(e)}}
                          onBlur={e => blurHandler(e)}
                      />

                      <PhoneCountryMask
                          extraClass="edit-input-phone edit-input"
                          name="phone"
                          countries={listPhoneCountries}
                          country="RU"
                          loading={isFetching}
                          phone={phoneNumber}
                          handleChangeField={handleChangeField}
                          nameCountry={ADDRESS_PHONE_COUNTRY}
                          namePhone={ADDRESS_PHONE}
                          fluid={true}
                      />
                      <Input
                          className='venue-modal-placeholder'
                          type='text'
                          name='mail'
                          placeholder='Электронная почта'
                          transparent
                          onChange={e => mailHandler(e)}
                          onBlur={e => blurHandler(e)}
                      />
                      {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}

                      {/*{(textDirty && textError) && <div style={{color: 'red'}}>{textError}</div>}*/}
                      <Input
                          className='venue-modal-placeholder'
                          type='text'
                          name='question'
                          placeholder='Ваш вопрос'
                          transparent
                          onChange={e => {textHandler(e)}}
                          onBlur={e => blurHandler(e)}
                      />

                      <Button className="venue-modal-btn" onClick={handleSubmitQuestion}>Отправить</Button>

                  </Modal.Content>
                  <Dimmer inverted active={isLoading} className={'venue-dimmer'}>
                      <Loader active={isLoading}>Отправка сообщения</Loader>
                  </Dimmer>
              </Modal>
          </BrowserView>

            <MobileView>
                  <div className='venue-main-menu-bottom mobile'>
                          <div className='venue-main-menu row-container'>
                              <div className='venue-main-menu row-block' onClick={() => navigate('/#')}>
                                  <HomeIcon height='25px' width='25px' className='venue-bottom-bar-icon'/>
                                  <span className='venue-main-menu column-text mobile'>Главная</span>
                              </div>
                              <div className='venue-main-menu row-block' onClick={() => navigate(`/favorite`)}>
                                  <img src={HeartIcon} height='25px' className='venue-bottom-bar-icon'/>
                                  <span className='venue-main-menu column-text mobile'>Избранное</span>
                              </div>
                              <div className='venue-main-menu row-block' onClick={() => navigate(`/catalog`)}>
                                  <img src={CatalogIcon} height='25px' className='venue-bottom-bar-icon'/>
                                  <span className='venue-main-menu column-text mobile'>Каталог</span>
                              </div>
                              <div className='venue-main-menu row-block' onClick={handleOpenSearch}>
                                  <SearchIcon width='25px' height='25px' className='venue-bottom-bar-icon'/>
                                  <span className='venue-main-menu column-text mobile'>Поиск</span>
                              </div>
                          </div>
                  </div>
            </MobileView>
      </>
  );
}