import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Dimmer, Header, Loader } from 'semantic-ui-react';
import ProductCard from '../ProductCard/ProductCard';
import './style.css';
import { getProductsFetching } from "../../views/Products/reducer";
import { fetchCatalogue } from "../../views/Categories/actions";

const ProductsContainer = (props) => {
    const {
        categoryScreen, // Порядковый номер show_in_cat_screen
        loaderText,
        zeroText,
    } = props;

    const dispatch = useDispatch();

    const categories = useSelector(state => state.categories.items || []);
    const products = useSelector(state => state.products.items || []);
    const fetching = useSelector(state => getProductsFetching(state.products));


    const selectedCategory = useMemo(
        () => categories.find(cat => cat.show_in_cat_screen === String(categoryScreen)),
        [categories, categoryScreen]
    );

    const filteredProducts = useMemo(() => {
        if (!selectedCategory || !selectedCategory.products_ids) return [];
        const productIds = selectedCategory.products_ids;
        return products.filter(product => productIds.includes(product.id));
    }, [selectedCategory, products]);

    return (
        <>
            <Dimmer active={fetching} inverted>
                <Loader active={fetching}>{loaderText}</Loader>
            </Dimmer>
            {!fetching && filteredProducts.length === 0 ? (
                <Container>
                    <p>{zeroText}</p>
                </Container>
            ) : (
                <div className="products-grid">
                    {filteredProducts.map(product => (
                        <div key={product.id}>
                            <ProductCard
                                id={product.id}
                                src={product.images[0]?.src}
                                name={product.name}
                                categories={product.categories}
                                tags={product.tags}
                                shortDescription={product.short_description}
                                has_options={product.has_options}
                                virtual={product.virtual}
                                seller_company={product.seller_company?.name}
                                seller_company_id={product.seller_company?.id}
                                seller_company_logo={product.seller_company?.logo}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ProductsContainer;
