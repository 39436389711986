import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import { getProducts, getProductsFetching } from "../Products/reducer";
import { isMobile, isBrowser } from 'react-device-detect';
import './style.css';
import translations from "../../components/LanguageModal/translations";
import { getLanguage } from "../../components/LanguageModal/reducer";
import ProductsContainer from "../../components/ProductsContainer";
import {getCategories} from "../Categories/reducer";
import {clearProducts} from "../Products/actions";
import {fetchCatalogue} from "../Categories/actions";

export default function Catalog(props) {

    const dispatch = useDispatch();
    const loading = useSelector((state) => getProductsFetching(state.products));
    const language = useSelector(getLanguage);
    const categories = useSelector(state => getCategories(state.categories));

    useEffect(() => {
        dispatch(clearProducts());
        window.scrollTo(0, 0);
        dispatch(fetchCatalogue(null))
    }, []);



    const t = (key) => {
        const languageString = language[0] + language[1];
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    // console.log(categories)
    // console.log(products)

    const animalHusbandryProducts = categories.find(cat => cat.cat_ID === 193)?.category_count || 0;
    const cropProductionProducts = categories.find(cat => cat.cat_ID === 197)?.category_count || 0;
    const manufacturingProducts = categories.find(cat => cat.cat_ID === 199)?.category_count || 0;
    const aquacultureProducts = categories.find(cat => cat.cat_ID === 371)?.category_count || 0;


    const wordsArray = [
        "товар",
        "товара",
        "товаров"
    ];

    function NormalizeCountForm(number, words_arr) {
        number = Math.abs(number);
        if (Number.isInteger(number)) {
            let options = [2, 0, 1, 1, 1, 2];
            return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
        }
        return words_arr[1]
    }



    const mobileView = (
        <div id="catalog-mobile">
            <div className={'title-text-mobile'} style={{fontSize: '20px'}}>{t('catalog')}</div>
            <div className={'catalog-section'}>
                <Link className={'title-text-mobile'} to={"/catalog/animal-husbandry"} style={{
                    fontSize: '20px',
                    marginBottom: '5px',
                    marginTop: '10px',
                    height: '40px',
                    textAlign: "left"
                }}>
                    Кормовые добавки <br/> и ветеринария <ArrowIcon style={{width: '17px', marginLeft: "5px"}}/>
                </Link>
                <h2 className={'main-text'} id={'amount-tovar'} style={{fontSize: '13px', marginTop: '22px'}}>
                    {animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'}
                style={{fontSize: '10px', marginTop: '0px', marginBottom: '7px'}}>{t('browseOffers')}</h3>
            <div className="catalog-filter-container">
            </div>
            <ProductsContainer
                categoryScreen={2}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            <div className={'catalog-section'}>
                <h1 style={{marginBottom: 0}}>
                    <Link className={'title-text-mobile'} to={"/catalog/plant-production"} style={{
                        fontSize: '20px',
                        marginBottom: '5px',
                        marginTop: '5px',
                        height: '25px',
                        textAlign: "left"
                    }}>
                        Сельское хозяйство <ArrowIcon style={{width: '17px', marginLeft: "5px"}}/>
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{fontSize: '13px', marginTop: '22px'}}>
                    {cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'}
                style={{fontSize: '10px', marginTop: '0px', marginBottom: '7px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={1}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text-mobile'} to={"/technique"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>*/}
            {/*            {t('technique')} <ArrowIcon style={{ width: '17px' }} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>*/}
            {/*        {techniqueProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={techniqueProductsId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text-mobile'} to={"/services"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>*/}
            {/*            {t('services')} <ArrowIcon style={{ width: '17px' }} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>*/}
            {/*        {anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={cropProductionCategoryId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            <div className={'catalog-section'}>
                <h1 style={{marginBottom: 0}}>
                    <Link className={'title-text-mobile'} to={"/catalog/manufacturing-products"} style={{
                        fontSize: '20px',
                        marginBottom: '5px',
                        marginTop: '5px',
                        justifyContent: 'left',
                        textAlign: 'left',
                        height: '45px',
                        lineHeight: 1
                    }}>
                        Продукты питания <br/> и напитки <ArrowIcon style={{width: '17px', marginLeft: "5px"}}/>
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{fontSize: '13px', marginTop: '22px'}}>
                    {manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'}
                style={{fontSize: '10px', marginTop: '0px', marginBottom: '7px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={4}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            <div className={'catalog-section'}>
                <h1 style={{marginBottom: 0}}>
                    <Link className={'title-text-mobile'} to={"/catalog/aquaculture-and-fisheries"} style={{
                        fontSize: '20px',
                        marginBottom: '5px',
                        marginTop: '5px',
                        justifyContent: 'left',
                        textAlign: 'left',
                        height: '45px',
                        lineHeight: 1
                    }}>
                        Аквакультура <br/>и рыболовство <ArrowIcon style={{width: '17px', marginLeft: "5px"}}/>
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{fontSize: '13px', marginTop: '22px'}}>
                    {aquacultureProducts} {NormalizeCountForm(aquacultureProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'}
                style={{fontSize: '10px', marginTop: '0px', marginBottom: '7px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={3}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
        </div>
    );

    const browserView = (
        <div className="catalog">
            <h1 className={'title-text'} id={'title'}>{t('catalog')}</h1>
            <div className={'catalog-section'}>
                <h1 style={{marginBottom: 0}}>
                    <Link className={'title-text'} to={"/catalog/animal-husbandry"}>
                        Кормовые добавки и ветеринария <ArrowIcon style={{marginBottom: '6px'}}/>
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop: '10px', marginBottom: '10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={2}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/plant-production"}>
                        Сельское хозяйство <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={1}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text'} to={"/technique"}>*/}
            {/*            {t('technique')} <ArrowIcon style={{ marginBottom: '6px'}} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'}>*/}
            {/*        {techniqueProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={techniqueProductsId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text'} to={"/services"}>*/}
            {/*            {t('services')} <ArrowIcon style={{ marginBottom: '6px'}} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'}>*/}
            {/*        {anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={anotherProductsId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/manufacturing-products"}>
                        Продукты питания и напитки <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={4}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/aquaculture-and-fisheries"}>
                        Аквакультура и рыболовство <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {aquacultureProducts} {NormalizeCountForm(aquacultureProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                categoryScreen={3}
                loaderText="Загрузка продуктов..."
                zeroText="Нет доступных продуктов."
            />
        </div>
    );

    return (
        <div className="catalog">
            {isMobile ? mobileView : isBrowser ? browserView : null}
        </div>
    );
}
